import { isDateWithinRange } from "@/utils/date-helper";
import type { Scheduling } from "@xxl/content-api";

function isScheduledItem(obj: unknown): obj is Scheduling {
  if (obj === null) {
    return false;
  }
  const candidate = obj as Record<string, unknown>;
  return "fromDate" in candidate || "toDate" in candidate;
}

export const isScheduled = (item: unknown): boolean => {
  return isScheduledItem(item) ? isDateWithinRange(item) : true;
};
