import { HomepageContent } from "@/components/HomepageContent/HomepageContent";
import { Section } from "@/components/HomepageContent/HomepageContent.styled";
import type { HomePageProps } from "@/next-js-app/types/pages";
import { getHomePageProps } from "@/pages/homepagewip/getServerSideProps";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { SANITY_TESTS_MAP } from "@/react-utils/Symplify/constants";
import { useSymplifyWithSanity } from "@/react-utils/Symplify/hooks";
import { withPageData } from "@/utils/common-page-data/common-page-data";
import { SYMPLIFY_ORIGINAL_KEY } from "@/utils/constants";
import { getMetaRobots } from "@/utils/meta-robots";
import { getFullPageTitle } from "@/utils/page-helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { HomePage20ContentModules } from "@xxl/content-api";
import type { NextPage } from "next";
import Head from "next/head";
import { useEffect, useState } from "react";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

const HomePage: NextPage<HomePageProps> = ({
  homepageContent,
  abTestsPage,
}) => {
  const { t } = useTranslations();
  const pageTitle = getFullPageTitle(t("home.page.title"), t);
  const metaDescription = t("home.page.meta.description");
  const [pageContent, setPageContent] = useState<HomePage20ContentModules>([]);
  const hasSymplify = useSymplifyWithSanity("HOMEPAGE_SANITY_TEST");
  const {
    configuration: {
      frontendApi: { basePath },
    },
    isTeamsales,
  } = useSharedData().data;

  useEffect(() => {
    if (hasSymplify === true && isNotNullOrUndefined(abTestsPage)) {
      const activeProjects = window.symplify.getActivatedProjects();
      const homePageProject = activeProjects.find((item) =>
        item.projectName
          .toLowerCase()
          .includes(SANITY_TESTS_MAP.CAMPAIGN_PAGE_SANITY_TEST.PROJECT_NAME)
      );
      if (
        isNotNullOrUndefined(homePageProject) &&
        homePageProject.variationName !== SYMPLIFY_ORIGINAL_KEY
      ) {
        setPageContent(abTestsPage.contentModules);
      }
    } else {
      setPageContent(homepageContent);
    }
  }, [abTestsPage, hasSymplify, homepageContent]);
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          name="robots"
          content={getMetaRobots({ isTeamsales, isHomePage: true })}
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={basePath} />
      </Head>
      <Section>
        <HomepageContent contentModules={pageContent} />
      </Section>
    </>
  );
};

export const getServerSideProps = withPageData(getHomePageProps);

export default HomePage;
