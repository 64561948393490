import styled from "@emotion/styled/macro";
import { MQ } from "react-app/src/styles/helpers";
import {
  fontSizeScale,
  fontSizes,
} from "react-app/src/styles/theme/typography";

const { 6: DESKTOP_FONT_SIZE } = fontSizeScale;

export const Description = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

export const Heading = styled.h2`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: ${fontSizes.big}px;
  ${MQ("laptop")} {
    font-size: ${DESKTOP_FONT_SIZE}px;
  }
`;
