import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "../../../styles/helpers";
import { gap } from "../../Common/util";

export const Wrapper = styled.div(css`
  display: grid;
  gap: ${gap.mobile};
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    "first first"
    "third fourth"
    "second second";

  ${MQ("tablet")} {
    gap: ${gap.laptop};
    grid-template-columns: calc(50% - ${gap.laptop}/2) auto auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "first second third" "first second fourth";
   `);
