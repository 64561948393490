import styled from "@emotion/styled/macro";

// h1 that is hidden visually but accessible to screen readers and search engines
const HiddenH1 = styled.h1`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
`;

export default HiddenH1;
