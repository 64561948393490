import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { isHomepageHighPrioComponent } from "@/react-components/Banners/Shared/BannerContent/BannerContent.helper";
import { HighlightedCategories } from "@/react-components/HighlightedCategories/HighlightedCategories";
import { stringToRecommendationStrategy } from "@/react-components/PersonalizedProductList/personalized-product-list-helper";
import { useMediaQuery } from "@mui/material";
import { hasNoValue, hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  CampaignCarousel as CampaignCarouselType,
  ContentProductCarousel,
  FourGridBanners,
  FullGridComponent,
  HalfGridComponent,
  HighlightedCategoriesComponent,
  HomePage20ContentModules,
  HomePageCategories,
  InternalIdentifier,
  MiniBanner as MiniBannerType,
  QuarterGridComponent,
  TwoColumnBanners,
  TwoColumnGrid,
} from "@xxl/content-api";
import { log } from "@xxl/logging-utils";
import React, { useEffect, useState } from "react";
import { FullGridBanner } from "react-app/src/components/Banners/FullGrid/FullGridBanner";
import { HalfGridBanner } from "react-app/src/components/Banners/HalfGrid/HalfGridBanner";
import { QuarterGridBanner } from "react-app/src/components/Banners/QuarterGrid/QuarterGridBanner";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { MiniBanner } from "react-app/src/components/MiniBanner/MiniBanner";
import type { MiniBannerNonOptional } from "react-app/src/components/MiniBanner/MiniBanner.helper";
import { PopularCategories } from "react-app/src/components/PopularCategories/PopularCategories";
import type { ProductBannerPricing } from "react-app/src/components/PriceDisplays/price-display-helper";
import {
  BannerSize,
  convertToBannerPricing,
  fetchPriceDisplays,
} from "react-app/src/components/PriceDisplays/price-display-helper";
import { BrandList } from "react-app/src/components/Search/Brands/BrandList";
import { UspComponent } from "react-app/src/components/UspComponent/UspComponent";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { useSession } from "react-app/src/hooks/useSession";
import { useSharedData } from "react-app/src/contexts/SharedData";
import {
  laptopMediaQuery,
  mobileMediaQuery,
  mobileTabletLaptopAndSmallDesktopMediaQuery,
  tabletAndDesktopMediaQuery,
} from "react-app/src/utils/xxl-screen";
import { CampaignCarouselComponent } from "../CampaignCarousel/CampaignCarouselComponent";
import {
  getTitle,
  RecommendationStrategies,
} from "../PersonalizedProductLists/Handler/Handler.helper";
import { getComponentSpacing } from "../ProductDetailsPage/ProductDetailsPage.helper";
import { SiteContainer } from "../common/SiteContainer/SiteContainer";
import {
  AllComponentsWrapper,
  BannerComponentWrapper,
  ComponentWrapper,
} from "./HomepageContent.styled";
import { ContentProductCarouselComponent } from "../common/ContentProductCarousel/ContentProductCarousel";
import { Guides } from "./components/Guides/Guides";
import { Heading } from "./components/Guides/Guides.styled";
import { LegacyBanners } from "./components/LegacyBanner/LegacyBanner";
import {
  BREAKPOINTS,
  createProductFetchInfo,
  MODULE_TYPES,
  setBrands,
  setUsps,
  type BrandsMaxAmountProps,
  type UspsMaxAmountProps,
} from "./utils";
import { isScheduled } from "@/utils/scheduling-helper";
import HiddenH1 from "./components/HiddenH1.styled";

type HomepageContentProps = {
  contentModules: HomePage20ContentModules;
};

export const HomepageContent: React.FunctionComponent<HomepageContentProps> = ({
  contentModules,
}) => {
  const {
    sessionState: { isLoggedIn },
  } = useSession();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isTablet = useMediaQuery(mobileTabletLaptopAndSmallDesktopMediaQuery);
  const isTabletAndDesktop = useMediaQuery(tabletAndDesktopMediaQuery);
  const [breakpoint, setBreakpoint] = useState(BREAKPOINTS[2]);
  const [priceData, setPriceData] = useState<ProductBannerPricing[] | null>(
    null
  );
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const componentSpacing = getComponentSpacing({ isLaptopSize });
  const { siteUid } = useSharedData().data;
  const { pricesApi } = useApiClients();
  const { t } = useTranslations();

  useEffect(() => {
    if (isMobile && !isTabletAndDesktop) {
      setBreakpoint(BREAKPOINTS[0]);
    } else if (isTablet && isTabletAndDesktop) {
      setBreakpoint(BREAKPOINTS[1]);
    } else {
      setBreakpoint(BREAKPOINTS[2]);
    }
  }, [isMobile, isTablet, isTabletAndDesktop]);

  useEffect(() => {
    if (contentModules.length === 0 || priceData !== null) {
      return;
    }

    const { isFetchPrioritised, productCodes } =
      createProductFetchInfo(contentModules);
    const gridBanners = contentModules
      .flatMap((banner) => {
        if (banner._type === "TwoColumnGrid") {
          return (banner as TwoColumnGrid).twoColumnGrid;
        }

        if (banner._type === "fourGridBanners") {
          return (banner as FourGridBanners).fourGridBanners;
        }

        return null;
      })
      .filter(isNotNullOrUndefined);

    const columnBanners = contentModules
      .flatMap((banner) =>
        banner._type === "TwoColumnBanners"
          ? (banner as TwoColumnBanners).twoColumnBanners
          : null
      )
      .filter(isNotNullOrUndefined);

    const fetchAndSetProducts = () => {
      const fetch = async () => {
        const priceDisplays = await fetchPriceDisplays(productCodes, pricesApi);
        setPriceData(
          convertToBannerPricing(priceDisplays, t, isLoggedIn, siteUid, [
            ...gridBanners,
            ...columnBanners,
          ])
        );
      };
      void fetch();
    };

    if (isFetchPrioritised) {
      try {
        fetchAndSetProducts();

        document.removeEventListener("scroll", fetchAndSetProducts);
        return;
      } catch (error) {
        log.error(error);
        return;
      }
    }

    if (productCodes.length === 0) {
      return;
    }

    document.addEventListener("scroll", fetchAndSetProducts);

    return () => document.removeEventListener("scroll", fetchAndSetProducts);
  }, [contentModules, isLoggedIn, priceData, pricesApi, siteUid, t]);

  return isNotNullOrUndefined(contentModules) ? (
    <AllComponentsWrapper>
      <HiddenH1>{t("home.page.title")}</HiddenH1>
      {contentModules.map((module, index) => {
        const moduleType = module._type;
        const isHighPrioComponent = isHomepageHighPrioComponent(index);

        if (moduleType === MODULE_TYPES.BRANDS && "brandsComponent" in module) {
          const brands =
            isNotNullOrUndefined(module.brandsComponent) &&
            "brands" in module.brandsComponent &&
            setBrands(
              module.brandsComponent,
              breakpoint as BrandsMaxAmountProps
            );
          return brands !== false &&
            isNotNullOrUndefined(brands) &&
            brands.length > 0 ? (
            <ErrorBoundary key={index}>
              <SiteContainer hideBottomPadding={true}>
                <ComponentWrapper>
                  <BrandList
                    brands={brands}
                    isFullWidth={true}
                    isHighPrioComponent={isHighPrioComponent}
                  />
                </ComponentWrapper>
              </SiteContainer>
            </ErrorBoundary>
          ) : null;
        }

        if (moduleType === MODULE_TYPES.FULL_GRID) {
          const { fullGridBanners } = module as FullGridComponent;

          if (!isNotNullOrUndefined(fullGridBanners)) {
            return null;
          }

          const scheduledBanners = fullGridBanners.filter(isScheduled);

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <FullGridBanner
                      content={scheduledBanners}
                      isHighPrioComponent={isHighPrioComponent}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }
        if (moduleType === MODULE_TYPES.USPS && "uspTeasers" in module) {
          const usps = module.uspTeasers ?? null;

          if (isNotNullOrUndefined(usps) && usps.length > 0) {
            const filteredUsps = setUsps(
              usps,
              breakpoint as UspsMaxAmountProps
            );
            return (
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <UspComponent usps={filteredUsps} />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            );
          }
        }

        if (moduleType === MODULE_TYPES.GUIDES && "guides" in module) {
          const guides = module.guides ?? null;

          if (isNotNullOrUndefined(guides) && guides.length > 0) {
            return (
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    {"title" in module &&
                      isNotNullOrUndefined(module.title) && (
                        <Heading>{module.title}</Heading>
                      )}
                    <XxlStack mt={componentSpacing} />
                    <Guides
                      guides={guides}
                      isHighPrioComponent={isHighPrioComponent}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            );
          }
        }

        if (moduleType === MODULE_TYPES.CONTENT_PRODUCT_CAROUSEL) {
          const {
            buttons,
            descriptionField,
            disclaimerInformation,
            imagePosition,
            productRecommendation,
            titleField,
            visualMedia,
          } = module as ContentProductCarousel & InternalIdentifier;

          const {
            brandNames,
            campaignIds,
            categoryCodes,
            colorTheme,
            productIds,
            productsCount,
            strategy: strategyString,
          } = productRecommendation ?? {};

          const { displayName, url } = buttons?.at(0) ?? {};
          const { categoryTitle } = titleField;
          const [category] = hasValue(categoryTitle)
            ? categoryTitle
            : [{ displayName: undefined, ecomCode: undefined }];

          const strategy = stringToRecommendationStrategy(
            hasValue(strategyString)
              ? strategyString.toString()
              : RecommendationStrategies.personalized
          );

          if (
            strategy !== "bestseller" &&
            strategy !== "personalized" &&
            strategy !== "popularity"
          ) {
            return null;
          }

          return (
            <ErrorBoundary key={index}>
              <ComponentWrapper>
                <ContentProductCarouselComponent
                  backgroundColor={colorTheme?.value}
                  categoryCode={category.ecomCode}
                  brands={brandNames}
                  campaigns={campaignIds}
                  categories={categoryCodes}
                  descriptionField={descriptionField}
                  disclaimerInformation={disclaimerInformation}
                  fontColor={colorTheme?.font}
                  hasTopMargin={false}
                  imagePosition={imagePosition}
                  isHighPrioComponent={isHighPrioComponent}
                  linkText={displayName}
                  linkUrl={url}
                  includedProducts={productIds}
                  productsCount={productsCount}
                  strategy={strategy}
                  title={titleField.title ?? category.displayName}
                  visualMedia={visualMedia}
                />
              </ComponentWrapper>
            </ErrorBoundary>
          );
        }

        if (
          moduleType === MODULE_TYPES.RECOMMENDATIONS &&
          "strategy" in module
        ) {
          const strategy = stringToRecommendationStrategy(
            hasValue(module.strategy)
              ? module.strategy.toString()
              : RecommendationStrategies.personalized
          );
          const {
            brandNames,
            campaignIds,
            categoryCodes,
            colorTheme,
            recommendationsLink,
            productIds,
            productsCount,
            title,
            subTitle,
          } = module;

          if (
            strategy === "personalized" ||
            strategy === "bestseller" ||
            strategy === "popularity"
          ) {
            return (
              <ErrorBoundary key={index}>
                <ComponentWrapper>
                  <ContentProductCarouselComponent
                    backgroundColor={colorTheme?.value}
                    brands={brandNames}
                    campaigns={campaignIds}
                    categories={categoryCodes}
                    fontColor={colorTheme?.font}
                    hasTopMargin={false}
                    includedProducts={productIds}
                    isHighPrioComponent={isHighPrioComponent}
                    linkText={recommendationsLink?.displayName}
                    linkUrl={recommendationsLink?.url}
                    productsCount={productsCount}
                    strategy={strategy}
                    subTitle={subTitle}
                    title={title ?? getTitle(strategy, t)}
                  />
                </ComponentWrapper>
              </ErrorBoundary>
            );
          }

          log.error("Unknown recommendation strategy: ", strategy);
          return null;
        }
        if (module._type === MODULE_TYPES.MINI_BANNER) {
          const moduleData = module as MiniBannerType;
          const bannerData: MiniBannerNonOptional = {
            ...moduleData,
            backgroundImage:
              isNotNullOrUndefined(moduleData.backgroundImage) &&
              isNotNullOrUndefined(moduleData.backgroundImage.url)
                ? {
                    baseUrl: moduleData.backgroundImage.url,
                  }
                : null,
          } as MiniBannerNonOptional;
          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <MiniBanner bannerData={bannerData} />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }
        if (module._type === MODULE_TYPES.CATEGORIES) {
          const moduleData = module as HomePageCategories;
          return (
            <ErrorBoundary key={index}>
              <SiteContainer hideBottomPadding={true}>
                <ComponentWrapper>
                  <PopularCategories
                    title={moduleData.title}
                    level1Categories={moduleData.level1Categories}
                    isHighPrioComponent={isHighPrioComponent}
                  />
                </ComponentWrapper>
              </SiteContainer>
            </ErrorBoundary>
          );
        }
        if (module._type === MODULE_TYPES.HIGHLIGHTED_CATEGORIES) {
          const moduleData = module as HighlightedCategoriesComponent;
          return (
            <ErrorBoundary key={index}>
              <SiteContainer hideBottomPadding={true}>
                <ComponentWrapper>
                  <HighlightedCategories
                    title={moduleData.title}
                    level1Categories={moduleData.level1Categories}
                  />
                </ComponentWrapper>
              </SiteContainer>
            </ErrorBoundary>
          );
        }

        if (module._type === MODULE_TYPES.HALF_GRID) {
          const { halfGridBanners } = module as HalfGridComponent;

          if (halfGridBanners === undefined) {
            log.error("Half Grid Banner is undefined.");
            return null;
          }

          if (halfGridBanners.length !== 2) {
            log.error(
              `The Half Grid components expects 2 banners but got ${halfGridBanners.length}.`
            );
            return null;
          }
          const [first, second] = halfGridBanners;

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <HalfGridBanner
                      firstBanner={first}
                      secondBanner={second}
                      isHighPrioComponent={isHighPrioComponent}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.CAMPAIGN_CAROUSEL) {
          const campaignCarouselModule = module as CampaignCarouselType;
          const { productRecommendation } = campaignCarouselModule;

          if (hasNoValue(productRecommendation)) {
            log.error("Campaign carousel has no recommendations.");
            return null;
          }

          const {
            strategy,
            brandNames,
            campaignIds,
            categoryCodes,
            colorTheme,
            productsCount,
            productIds,
          } = productRecommendation;

          if (
            strategy !== RecommendationStrategies.bestseller &&
            strategy !== RecommendationStrategies.personalized &&
            strategy !== RecommendationStrategies.popularity
          ) {
            log.error(
              "Campaign carousel has wrong recommendations strategy: ",
              strategy
            );
            return null;
          }

          return (
            <ErrorBoundary key={index}>
              <ComponentWrapper>
                <CampaignCarouselComponent
                  key={index}
                  module={campaignCarouselModule}
                  isHighPrioComponent={isHighPrioComponent}
                  recommendationInfo={{
                    strategy,
                    brandNames: brandNames,
                    campaignIds: campaignIds,
                    categoryIds: categoryCodes,
                    colorTheme: colorTheme?.value,
                    includedProducts: productIds,
                    productsCount,
                  }}
                />
              </ComponentWrapper>
            </ErrorBoundary>
          );
        }

        if (module._type === MODULE_TYPES.QUARTER_GRID) {
          const {
            halfGridBanners,
            verticalGridBanners,
            horizontalGridBanners,
          } = module as QuarterGridComponent;

          if (halfGridBanners.length !== 1) {
            log.error(
              `Expected Half grid banner count to be 1, got ${halfGridBanners.length}.`
            );
            return null;
          }

          if (verticalGridBanners.length !== 1) {
            log.error(
              `Expected Vertical grid banner count to be 1, got ${halfGridBanners.length}.`
            );
            return null;
          }

          if (horizontalGridBanners.length !== 2) {
            log.error(
              `Expected Horizontal grid banner count to be 2 got ${halfGridBanners.length}.`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <QuarterGridBanner
                      isHighPrioComponent={isHighPrioComponent}
                      firstBanner={halfGridBanners[0]}
                      secondBanner={verticalGridBanners[0]}
                      thirdBanner={horizontalGridBanners[0]}
                      fourthBanner={horizontalGridBanners[1]}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.MEDIUM_BANNER) {
          const { twoColumnGrid } = module as TwoColumnGrid;

          if (!isNotNullOrUndefined(twoColumnGrid)) {
            log.error("Two column grid banner is not defined.");
            return null;
          }

          if (twoColumnGrid.length !== 2) {
            log.error(
              `Two column grid banner has an unexpected length: ${twoColumnGrid.length}`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true} hasPadding={true}>
                  <ComponentWrapper>
                    <LegacyBanners
                      banners={twoColumnGrid}
                      size={BannerSize.MEDIUM}
                      priceData={priceData ?? []}
                      isHighPrioComponent={isHighPrioComponent}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.SMALL) {
          const { twoColumnBanners } = module as TwoColumnBanners;

          if (!isNotNullOrUndefined(twoColumnBanners)) {
            log.error("Two column grid banner is not defined.");
            return null;
          }

          const scheduledBanners = twoColumnBanners.filter(isScheduled);

          if (scheduledBanners.length !== 2) {
            log.error(
              `Two column grid banner has an unexpected length: ${scheduledBanners.length}`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true} hasPadding={true}>
                  <ComponentWrapper>
                    <LegacyBanners
                      banners={scheduledBanners}
                      size={BannerSize.SMALL}
                      priceData={priceData ?? []}
                      isHighPrioComponent={isHighPrioComponent}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.XS_BANNER) {
          const { fourGridBanners } = module as FourGridBanners;

          if (!isNotNullOrUndefined(fourGridBanners)) {
            log.error("Four grid grid banner is not defined.");
            return null;
          }

          if (fourGridBanners.length !== 4) {
            log.error(
              `Four column banner has unexpected length: ${fourGridBanners.length}`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true} hasPadding={true}>
                  <ComponentWrapper>
                    <LegacyBanners
                      banners={fourGridBanners}
                      size={BannerSize.XS}
                      priceData={priceData ?? []}
                      isHighPrioComponent={isHighPrioComponent}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        log.debug("Homepage moduletype not recognised: ", module._type);
        return null;
      })}
    </AllComponentsWrapper>
  ) : null;
};
