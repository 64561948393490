import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useState } from "react";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

export const getColorTheme = (priceDisplay: {
  colourTheme?: {
    backgroundColour?: string;
    foregroundColour?: string;
    name?: string;
  };
  colorTheme?: {
    backgroundColor?: string;
    foregroundColor?: string;
    name?: string;
  };
}) => {
  const colors = {
    name: "",
    backgroundColor: "",
    foregroundColor: "",
  };
  if ("colourTheme" in priceDisplay) {
    if (
      priceDisplay.colourTheme?.backgroundColour !== undefined &&
      priceDisplay.colourTheme.foregroundColour !== undefined
    ) {
      colors.backgroundColor = priceDisplay.colourTheme.backgroundColour;
      colors.foregroundColor = priceDisplay.colourTheme.foregroundColour;
      colors.name = priceDisplay.colourTheme.name ?? "";
      return colors;
    }
  }
  if (isNotNullOrUndefined(priceDisplay.colorTheme)) {
    if (
      priceDisplay.colorTheme.backgroundColor !== undefined &&
      priceDisplay.colorTheme.foregroundColor !== undefined &&
      priceDisplay.colorTheme.foregroundColor !== ""
    ) {
      colors.backgroundColor = priceDisplay.colorTheme.backgroundColor;
      colors.foregroundColor = priceDisplay.colorTheme.foregroundColor;
      colors.name = priceDisplay.colorTheme.name ?? "";
      return colors;
    }
  }
  return colors;
};

export const getComponentSpacing = ({
  isLaptopSize,
}: {
  isLaptopSize: boolean;
}) => (isLaptopSize ? xxlTheme.spaces.large : xxlTheme.spaces.smallRegular);

export const useIsSizeSelectDrawerOpen = () => {
  const [isSizeSelectDrawerOpen, setIsSizeSelectDrawerOpen] = useState(false);
  const toggleIsSizeSelectDrawerOpen = () =>
    setIsSizeSelectDrawerOpen(!isSizeSelectDrawerOpen);
  return {
    isSizeSelectDrawerOpen,
    setIsSizeSelectDrawerOpen,
    toggleIsSizeSelectDrawerOpen,
  };
};
